<template>
	<div class="cc-card">
		<v-btn x-small :color="data.MapDayNew < 4 ? 'info': 'error' "  style="z-index: 2;transform: rotateZ(0deg);margin-top: -15px;margin-left: 40px;position: absolute;width:;border-radius: 20px;">Días:  {{data.MapDayNew}} = {{data.MapMinuteNew}}</v-btn>
		<v-btn
			x-small
			elevation="0"
			fab
			dark
			color="info"
			style="margin-left: -15px; margin-top: -20px"
			@click="viewMovimients()"
		>
			<v-icon style="font-size: 16px !important"
				>fas fa-eye</v-icon
			>
		</v-btn>
		<v-btn :color="data.LlpBalancePartition == 1 ? 'warning' : data.LlpBalancePartition == 2 ? 'error' : data.LlpBalancePartition == 3 ? 'success' : ''" v-if="data.LlpBalancePartition !== null" style="z-index: 2;transform: rotateZ(270deg);margin-top: 50px;margin-left: 90px;position: absolute;width: 133px;border-radius: 20px;">{{data.LlpBalancePartition == 1 ? 'Partición' : data.LlpBalancePartition == 2 ? 'Union' : data.LlpBalancePartition == 3 ? 'Verde Proceso.' : ''}}</v-btn>
		<!-- <v-btn :color="data.LlpOrigintc == 2 && data.LlpIsPartition == 0 ? 'warning' : 'error'" v-if="data.LlpOrigintc == 2 || data.LlpIsPartition == 1" style="z-index: 2;transform: rotateZ(270deg);margin-top: 50px;margin-left: 90px;position: absolute;width: 133px;border-radius: 20px;">{{data.LlpOrigintc == 2 && data.LlpIsPartition == 0 ? 'Puchito' : 'Union'}}</v-btn> -->

		<h3>
			PA - {{data.LlpID}}
    
		</h3>
		<p style="-webkit-text-stroke: thick; font-size: 12px">
			{{data.LlpQuantityJaba}} Jabas - {{data.LlpWeight}} KG
		</p>
        <b>{{data.TypeFrozenCategoryName}}</b>
        <br>
        <b style="font-size: 10px">{{data.TypeCropName}}, {{data.TypeCultiveName}} - {{data.VrtName}}</b>
		<br>
        <b style="font-size: 10px">Entrada: {{data.MapDateBegin}}</b>
		<!-- <b style="font-size: 14px">Días:  {{data.MapMinuteNew}} - {{data.MapMinute}}</b> -->
		
		<br>
		<b style="font-size: 10px" v-if="data.LlpIDDetail"><v-chip small>PA -{{data.LlpIDDetail}}</v-chip> </b>
		

		<v-btn
			x-small
			elevation="0"
			fab
			dark
			color="success"
			style="margin-left: 90px; margin-bottom: -5px;"
			@click="viewSendProccess()"
		>
			<i class="fas fa-share-square"></i>
		</v-btn>
		
	</div>
</template>

<script>
	export default {
		
		props: {data: null},
		methods: {
			viewMovimients(){
				this.$emit('viewMovimients', this.data)
			},

			viewSendProccess()
			{
				this.$emit('viewSendProccess', this.data);
			}
		},
	};
</script>
<style>
	.cc-card {
		padding: 10px;
		text-align: left;
		border: 1px solid #dbdbdb;
		border-radius: 5px;
		margin: 5px;
		background-color: white;
		min-width: 200px;
		font-size: 1em;
	}

	.cc-card h3 {
		margin: 0px;
	}
</style>

<template>
	<div>
		<!-- <v-container> -->
			<!-- <v-row>
				<div class="col-md-12 col-sm-12"> -->
					<v-card dense>
						<v-row style="margin: auto;" justify="center">
							<v-col
								align="center"
								cols="8"
								lg="4"
								md="4"
								v-for="item in itemsAmbient"
								:key="item.MamID"
							>
								<!-- <v-btn
									x-small
									elevation="0"
									fab
									dark
									color="error"
									style="margin-left: 5px; margin-top: 0px"
								>
									<v-icon style="font-size: 16px !important"
										>fas fa-eye</v-icon
									>
								</v-btn> -->
								

								<v-card 
									class="mx-auto"
									outlined
									:color="item.inTunnelStateColor"
									
								><!-- item.inTunnelStateColor -->
									<!-- <v-card-title class="justify-center">
										<v-card :color="item.inTunnelStateColor" outlined width="200"><h4>{{ item.FtmDescription }}</h4></v-card>
										
									</v-card-title> -->
									<v-row style="z-index:2;transform: rotateZ(0deg);margin-top: -2%;margin-left: -10%;position: absolute;width: 133px;border-radius: 20px;" v-if="item.inParihuelaUsage > 0" >
											<!-- <v-col cols="12" class="mt-0">
												<v-btn  v-if="item.inParihuelaUsage > 0" small color="error"  >Max. Dias Parih.: {{item.MamDay}}</v-btn>
											</v-col> -->
											<v-col cols="12" lg="12" style="margin-top: -20px">
												<v-btn  v-if="item.MamTemperatureBegin > 0" small color="error"  >Temp: {{item.MamTemperatureBegin}}</v-btn>
												<!-- <h1 v-if="item.MamTemperatureBegin > 0"><v-chip small color="info">Temp: {{item.MamTemperatureBegin}}</v-chip></h1> -->
											</v-col>
											<v-col cols="12" lg="12" style="margin-top: -20px">
												<v-btn  v-if="item.MamEthyleneHour > 0" small color="error"  >H. Etile: {{item.MamEthyleneHour}}</v-btn>
												<!-- <h1 v-if="item.MamEthyleneHour > 0"><v-chip small color="info">H. Etile: {{item.MamEthyleneHour}}</v-chip></h1> -->
											</v-col>
										</v-row>
									<v-img
										lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg"
										>
										
										
										<v-btn
											style=" margin: auto; display: flex; justify-content: space-evenly; "
											:color="item.inColor"
											fab
											@click="openModal(item)"
											class="mt-12 mb-4"
											width="100"
											height="100"
											
										>
											<span class="white--text text-h4"><b>
												<h1 style="font-size: 20px; ">
													{{item.inParihuelaUsage + ' TM'}}
												</h1>
											</b></span>
										</v-btn>										
										
										<frz-tons-in-day
											:TonsInDay="item.TonsInDay">
										</frz-tons-in-day>
									</v-img>

									
									<v-card outlined :color="item.inTunnelStateColor"  
											max-width="auto" 
											>
										<h2>{{ item.MamName }} 
											<v-chip small color="error">{{item.inEnvironmentStateName}} 
												<!-- <v-chip small color="info">{{item.ParihuelaAvailableUnit}}</v-chip> -->
											</v-chip>  
										</h2>
									</v-card>
									<!-- <v-row class="mt-1">
									<v-col cols="12">
										
										<v-btn
											v-if="item.inParihuelaUsage > 0"
											small
											fab
											elevation="3"
											color="info"
											@click="openDialogExitTunnel(item)"
										
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-eye</v-icon
											>
										</v-btn>
									</v-col>
									</v-row> -->
									<!-- <v-card-actions class="justify-center">
										<v-btn
											fab
											:color="item.inColor"
											style="opacity: 0.7"
											@click="openModal(item)"
											elevation="0"
										>
											<b>
												<h1 style="font-size: 50px; ">
													{{item.inParihuelaUsage}}
												</h1>
											</b>
										</v-btn>
										
									</v-card-actions> -->
								</v-card>
								<!-- <v-row style="margin-top: 0.1em;"> -->
									<!-- <v-col :cols="item.inParihuelaUsage > 0 ? 9 : 12">
										<s-select
											class="mt-1"
											:items="itemsStateTunnel"
											item-value="MtsID"
											item-text="MtsDescription"
											:slotTmp="true"
											return-object
											v-model="item.inTunnelState"
											@input="inputState($event, item)"
										>
											
										</s-select>
									</v-col> -->
									<!-- <v-col cols="3">
										<v-btn
											outlined
											small
											fab
											elevation="3"
											color="error"
											v-if="item.inParihuelaUsage > 0"
											
											
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-eye</v-icon
											>
										</v-btn>
									</v-col> -->
								<!-- </v-row> -->
								<!-- <s-select-definition class="mt-2" :def="1359" v-model="item.inTunnelState"></s-select-definition> -->
								
							</v-col>
						</v-row>
					</v-card>
				<!-- </div>
			</v-row> -->
		<!-- </v-container> -->

		<v-dialog
			v-model="dialog"
			v-if="dialog"
			transition="dialog-bottom-transition"
			persistent
			fullscreen
		>
			<pallet-in-chamber-detail
				@closeDetail = "closeDetail"
				:dataAmbient="dataAmbient"
				:MamID="MamID"
			></pallet-in-chamber-detail>
		</v-dialog>
		<!-- :dataPrihuela="dataPrihuela" -->
		<v-dialog
			v-model="dialogExitAmbient"
			v-if="dialogExitAmbient"
			width="500"
			persistent
		>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parámetros"
							save
							@save="saveProcessGroup()"
							close
							@close="closeDialogExitTunnel()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpTemperatureBegin"
								label="Temperatura"
							>

							</s-text>
						</v-col>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpEthyleneHour"
								label="Horas etileno"
							>

							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from '../../../services/FrozenProduction/MaturationTunnelPalletService';
    import sTunnelMaduration from "../../../services/FrozenProduction/TunnelMadurationService";
    import PalletInChamberDetail from './PalletInAmbientDetail.vue'; 
	import FrzTonsInDay from '../FrzMaduration/FrzTonsInDay.vue';
	import signalr from "signalr";
/* 	
	*/
	

	export default {
		components: { 
			 PalletInChamberDetail, FrzTonsInDay
		},

		data() {
			return {
				itemsAmbient: [],
				itemsStateTunnel: [],
				dialog:false,
				dataAmbient: {},
                dataPrihuela: [],
				dialogExitAmbient: false,
				processGroup: {},
				
			}
		},

		methods: {

			initialize(){

				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
					}
				})


				sTunnelMaduration.listEnviroment({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.MamCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.MamCapacity)- parseInt(element.inParihuelaUsage)
                            	//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
							
							element.TonsInDay.forEach(tonelas => {
								const fecha = new Date(tonelas.FechaIngreso);
								const date = fecha.getDate();
								const month = fecha.getMonth() + 1;								
								const format = date + '/'+ month;
								tonelas.FechaIngreso = format;								
							});
							
						});

						this.itemsAmbient = r.data;
						console.log("al levantar",this.itemsAmbient);

					}
					
				});
			},

			saveProcessGroup()
			{
				console.log("Guardar temperatura", this.processGroup);

				
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						_sMaturationTunnelPalletService
						.beginprocessgroup(this.processGroup ,this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Guardado Correctamente", "success");
								this.closeDialogExitTunnel();
							}
						})
					}
				})
			},

			openDialogExitTunnel(item)
			{
				this.processGroup.xFlag = 4;
				this.processGroup.TcaID = item.MamID;
				this.processGroup.UsrUpdateID = this.$fun.getUserID();
				this.dialogExitAmbient = true;
			},


			openModal(item)
			{
				this.dataAmbient = item;
				this.MamID = item.MamID;
				// let filter = {
				// 	MamID: item.MamID,
				// }
				this.dialog = true;
				// _sMaturationTunnelPalletService
				// .palletinsideambient(filter, this.$fun.getUserID()).then(resp => {
				// 	if(resp.status == 200){

				// 		resp.data.forEach(element => {
				// 			element.MapDateBegin = this.$moment(element.MapDateBegin).format(
				// 				this.$const.FormatDateTimeDB
				// 			);
				// 			element.MapSecondsNew = 
				// 				this.$fun.isDifferenceMinutes(
				// 					element.MapDateNewBegin,
				// 					element.MapDateNewEnd,
				// 					"seconds"
				// 				) * -1;

				// 			element.MapMinuteNew = this.$fun.secondsToString(element.MapSecondsNew)
				// 		});

				// 		this.dataPrihuela = resp.data;
                //         console.log("ffffffffffffffffffffff",this.dataPrihuela);
						
				// 	}
				// })

				
			},

			closeDialogExitTunnel()
			{
				this.dialogExitAmbient = false;
				this.initialize()
			},

			 closeDetail()
			{
				this.dialog = false;
				this.initialize()
			}	

			
		},

		created () {
			this.initialize();
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	}
</script>